<template>
  <div class="content">
    <el-row class="listHead" v-if="goods.length>0">
      <el-col :span="2">
        <label>
          <input type="checkbox" :checked="normalSelected" @change="goodsSelected()" /><span class="text">其他商品</span>
        </label>
      </el-col>
    </el-row>
    <div class="goods" v-for="(item, index) in goods" :key="index">
      <!-- <div v-if="!item.product.publishStatus" class="abBg">已下架</div> -->
      <el-col :span="2">
        <label>
          <input type="checkbox" :disabled="!item.product.publishStatus" :checked="item.flag"
            @change="handleGoodsChange(index)" />
        </label>
      </el-col>
      <el-col class="goodsImg" :span="10">
       <ShopImg :msg="item" :Datas="itemDatasArr"  :num="index"></ShopImg>
      </el-col>
      <el-col class="goodsPrice text-center" :span="3">{{
        item.product.price | capitalize
      }}</el-col>
      <el-col class="num text-center" :span="3">
        <div class="wraper" v-if="item.product.publishStatus">
          <button type='button' class="reduce text-center" @click="handleReducer(index)">-</button>
<!-- :max="item.product.stockNum"  -->
          <input 
            type="text" 
            v-model="item.quantity" 
            :max="item.product.isLimit==0?item.product.stockNum:item.product.stockNum>item.purchaseLimitationNum?item.purchaseLimitationNum:item.product.stockNum"
            :min="item.product.minOrderNum"
            :step="item.product.minOrderNum" 
            @change="handleChangValue(index)" 
            ref="input" />


          <button type='button' class="increase text-center" @click="handleAdd(index)">+</button>
        </div>
        <div class="wrapers" v-else><span class="text-center">{{item.quantity}}</span></div>
      </el-col>
      <el-col class="Subtotal text-center" :span="3">{{
        ((item.quantity * (item.product.price * 10000)) / 10000).toFixed(2) | capitalize
      }}</el-col>
      <el-col :span="3" class="text-center">
        <div class="operation text-center" @click="handleDel(index)">删除</div>

      </el-col>
    </div>
  </div>
</template>

<script>
  const ShopImg = () => import("components/common/ShopImg.vue");
  import {
    mapState,
    mapMutations,
    mapActions
  } from "vuex";
  export default {
    name: "CartBuyInfo",
    inject: ["reload"],
    data() {
      return {
         itemDatasArr:[],
      };
    },
      watch:{
        'itemDatas':function(){
            this.itemDatasArr=this.itemDatas
            //  alert(this.itemDatasArr)
          },
      },
      props:{
        itemDatas:{
          type:Array
        }
      },
    computed: {
      ...mapState({
        goods: (state) => state.goods,
        normalSelected: (state) => state.normalSelected
      }),
    },
    methods: {
      ...mapMutations({
        handleGoodsChange: "handleGoodsChange",
        handleReducer: "handleReducer",
        handleAdd: "handleAdd",
        handleChangValue: "handleChangValue",
        goodsSelected: 'goodsSelected'
      }),
      ...mapActions({
        handleDel: "handleDel"
      })

    },
    created() {},
    components: {
      ShopImg,
    },
  };
</script>

<style lang="less" scoped>
  @import "~style/index.less";

  [v-cloak] {
    display: none;
  }

  .listHead {
    color: #666666;
    font-size: 13px;
    line-height: 18px;
    margin-left: 10px;
    margin-top: 20px;

    .text {
      margin-left: 10px;
    }
  }

  .goods {
    width: 1198px;
    display: flex;
    align-items: center;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;

    // .abBg{
    //   position:absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //  background: #c1bfbf;
    //   color: #000;
    //   font-size: 30px;
    //   font-weight: bold;
    //   opacity: 0.3;
    //   z-index: 1;
    // }
    .operation {
      cursor: pointer;

      &:hover {
        color: @theme
      }
    }

    input {
      cursor: pointer;
    }

    & label {
      padding-left: 10px;
    }

    .wrapers {
      width: 110px;
      height: 36px;
      background: #f6f6f6;

      span {
        text-align: center;
        width: 100%;
        line-height: 36px;
      }
    }

    .wraper {
      width: 110px;
      height: 36px;
      background: #fff;
      display: flex;
      align-items: center;
      color: #666;

      &>button {
        width: 16px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f0f0f0;
        font-size: 15px;
        // border: 1px solid #F0F0F0;
        border: none;
        cursor: pointer;
      }

      input {
        display: inline-block;
        width: 70px;
        height: 36px;
        background: #f0f0f0;
        text-align: center;
        margin-left: 4px;
        margin-right: 4px;
        border: none;
        font-size: 14px;
        cursor: pointer;

        //  border: 1px solid #F0F0F0;
      }
    }
  }
</style>